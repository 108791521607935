import React, { useEffect } from "react";
import FrontLayout from "../layout-new-theme/FrontLayout";
import { useSelector, useDispatch } from "react-redux";
import { getPageContents } from "../../actions/admin/cmspage";
import { useLoading } from "./LoadingContext";
import { useTranslation } from "react-i18next";

const CancellationPolicy = () => {
  const page_name = "cms-cancellation-policy";
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const cmspage_state = useSelector((state) => state.cmspage);
  const { cmspage, loading } = cmspage_state;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  useEffect(() => {
    dispatch(getPageContents(page_name));
  }, [dispatch]);
  const { showLoading, closeLoading } = useLoading();
  // useEffect(() => {
  //   if (lang === null) return;
  //   i18n.changeLanguage(lang.toLowerCase());
  // }, []);

  return (
    <FrontLayout>
      {loading ? showLoading(true) : closeLoading(true)}
      <div
        className="hero-image-cancellation d-flex align-items-end"
        style={{
          background:
            "url('" +
            (lang === "EN"
              ? cmspage?.page_content_en.image1
              : cmspage?.page_content_jp.image1) +
            "')",
        }}
      >
        <div className="container col-md-11 col-lg-9 mx-auto custom-pt-8 mb-3 pb-lg-5 px-3 px-md-0">
          <div className="col-12 d-flex flex-column justify-content-end">
            <p
              className="card-header ps-0 text-gold"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content1
                    : cmspage?.page_content_jp.content1,
              }}
            >
              {/* {t("Pricing")} */}
            </p>
            <h1
              className="fw-bold mb-4 mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content2
                    : cmspage?.page_content_jp.content2,
              }}
            >
              {/* {t("Cancellation.Policy")} */}
            </h1>
            <p
              className="col-12 col-md-7 text-gold intro mb-5 mb-lg-0"
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? cmspage?.page_content_en.content3
                    : cmspage?.page_content_jp.content3,
              }}
            >
              {/* {t("Cancellation.Policy.Intro")} */}
            </p>
          </div>
        </div>
      </div>

      {/* <!-- cancellation policy --> */}
      <div className="container col-md-11 col-lg-9 mx-auto my-5 pt-lg-4 pb-lg-5 px-0">
        
          {/* <div
            className="col col-lg-10 offset-lg-1"
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? cmspage?.page_content_en.content4
                  : cmspage?.page_content_jp.content4,
            }}
          >
          </div> */}
          <div className="d-none d-md-block">
            <table className="w-100" id="cancellationTable">
              <tr>
                <td colSpan={2} className="bg-56273A">
                  <p className="fw-700 m-0 text-gold">{t("Translation.R1.T1")}</p>
                </td>
                <td className="bg-F1F1F2">
                  <p className="fw-700 m-0">{t("Translation.R1.T2")}</p>
                </td>
                <td className="bg-E6E7E8">
                  <p className="fw-700 m-0">{t("Translation.R1.T3")}</p>
                  <p className="text-7C7B7B fs-12 m-0" dangerouslySetInnerHTML={{__html : t("Translation.R1.T3.sp") }}>
                  </p>
                </td>
                <td className="bg-DCDDDE">
                  <p className="fw-700 m-0 ">{t("Translation.R1.T4")}</p>
                  <p className="text-7C7B7B fs-12 m-0" dangerouslySetInnerHTML={{ __html: t("Translation.R1.T4.sp") }}>
                  </p>
                </td>
                <td className="bg-D1D3D4">
                  <p className="fw-700 m-0" dangerouslySetInnerHTML={{__html :  t("Translation.R1.T5")}}>
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="bg-gold-light2">
                  <p className="m-0" dangerouslySetInnerHTML={{__html :  t("Translation.R2.T1")}}>
                    
                  </p>
                </td>
              </tr>

              <tr>
                <td colSpan={2} className="bg-E0D8DA">
                  <p className="fw-700 m-0">{t("Translation.R3.T1")}</p>
                </td>
                <td className="bg-F1F1F2">
                  <p className="m-0">{t("Translation.R3.T2")}</p>
                </td>
                <td className="bg-E6E7E8">
                  <p className="m-0">{t("Translation.R3.T3")}</p>
                </td>
                <td className="bg-DCDDDE">
                  <p className="m-0">{t("Translation.R3.T4")}</p>
                </td>
                <td className="bg-D1D3D4">
                  <p className="m-0">{t("Translation.R3.T5")}</p>
                </td>
              </tr>

              <tr>
                <td colSpan={2} className="bg-CBBDBF">
                  <p className="fw-700 m-0 ">{t("Translation.R4.T1")}</p>
                </td>
                <td className="bg-F1F1F2 border-top-white" dangerouslySetInnerHTML={{__html : t("Translation.R4.T2") }}>
                </td>
                <td className="bg-E6E7E8 border-top-white">
                  <p className="m-0">{t("Translation.R4.T3")}</p>
                </td>
                <td className="bg-DCDDDE border-top-white">
                  <p className="m-0">{t("Translation.R4.T4")}</p>
                </td>
                <td className="bg-D1D3D4 border-top-white">
                  <p className="m-0">{t("Translation.R4.T5")}</p>
                </td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-start bg-E0D8DA border-top-white border-right-white custom-td-w-13-percent" >
                  <p className="fw-700 mb-1 ms-3 me-2" dangerouslySetInnerHTML={{__html :t("Translation.R5.T1") }}>
                   
                  </p>
                  <p className="text-7C7B7B fs-12 ms-3  me-2" dangerouslySetInnerHTML={{__html :t("Translation.R5.T1.sp") }}>
                  
                  </p>
                </td>
                <td className="bg-D3CCCE border-top-white">
                  <p className="fw-700 mb-1">{t("Translation.R5.T2")}</p>
                  <p className="text-7C7B7B fs-12 m-0" dangerouslySetInnerHTML={{__html : t("Translation.R5.T2.sp")}}>
                    
                  </p>
                </td>
                <td colSpan={4} className="bg-white border-top-white">
                  <p className="m-0" >
                   {t("Translation.R5.T3")}
                  </p>
                </td>
              </tr>

              <tr>
                <td className="bg-CAC3C6 border-top-white">
                  <p className="fw-700 mb-1">{t("Translation.R6.T1")}</p>
                  <p className="text-7C7B7B fs-12 m-0" dangerouslySetInnerHTML={{__html : t("Translation.R6.T1.sp") }}>
                   
                  </p>
                </td>
                <td className="bg-F1F1F2 border-top-white" dangerouslySetInnerHTML={{__html : t("Translation.R6.T2")}}>
                </td>
                <td className="bg-E6E7E8 border-top-white" dangerouslySetInnerHTML={{__html : t("Translation.R6.T3")}}>
                </td>
                <td className="bg-DCDDDE border-top-white" dangerouslySetInnerHTML={{__html : t("Translation.R6.T4")}}>
                </td>

                <td className="bg-D1D3D4 border-top-white">
                  <p className="m-0" dangerouslySetInnerHTML={{__html : t("Translation.R6.T5")}}>
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="bg-gold-light2">
                  <p className="m-0" dangerouslySetInnerHTML={{__html : t("Translation.R7.T1")}}>
                  </p>
                </td>
              </tr>

              <tr>
                <td colSpan={2} className="bg-CBBDBF">
                  <p className="fw-700 m-0 ">{t("Translation.R8.T1")}</p>
                </td>
                <td className="bg-F1F1F2">
                  <p className="m-0">{t("Translation.R8.T2")}</p>
                </td>
                <td className="bg-E6E7E8">
                  <p className="m-0">{t("Translation.R8.T3")}</p>
                </td>
                <td className="bg-DCDDDE">
                  <p className="m-0">{t("Translation.R8.T4")}</p>
                </td>
                <td className="bg-D1D3D4">
                  <p className="m-0">{t("Translation.R8.T5")}</p>
                </td>
              </tr>
            </table>
          </div>
          <div className="d-block d-md-none">
            <table className="w-100" id="cancellationTable">
              <tr>
                <td  className="bg-56273A">
                  <p className="fw-700 m-0 text-gold">{t("Translation.R1.T1")}</p>
                </td>
                <td className="bg-F1F1F2">
                  <p className="fw-700 m-0">{t("Translation.R1.T2")}</p>
                </td>
                <td className="bg-E6E7E8">
                  <p className="fw-700 m-0">{t("Translation.R1.T3")}</p>
                  <p className="text-7C7B7B fs-12 m-0" dangerouslySetInnerHTML={{__html : t("Translation.R1.T3.sp") }}>
                  </p>
                </td>
                <td className="bg-DCDDDE">
                  <p className="fw-700 m-0 ">{t("Translation.R1.T4")}</p>
                  <p className="text-7C7B7B fs-12 m-0" dangerouslySetInnerHTML={{ __html: t("Translation.R1.T4.sp") }}>
                  </p>
                </td>
                <td className="bg-D1D3D4">
                  <p className="fw-700 m-0" dangerouslySetInnerHTML={{__html :  t("Translation.R1.T5")}}>
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan={5} className="bg-gold-light2">
                  <p className="m-0" dangerouslySetInnerHTML={{__html :  t("Translation.R2.T1")}}>
                    
                  </p>
                </td>
              </tr>

              <tr>
                <td  className="bg-E0D8DA">
                  <p className="fw-700 m-0">{t("Translation.R3.T1")}</p>
                </td>
                <td className="bg-F1F1F2">
                  <p className="m-0">{t("Translation.R3.T2")}</p>
                </td>
                <td className="bg-E6E7E8">
                  <p className="m-0">{t("Translation.R3.T3")}</p>
                </td>
                <td className="bg-DCDDDE">
                  <p className="m-0">{t("Translation.R3.T4")}</p>
                </td>
                <td className="bg-D1D3D4">
                  <p className="m-0">{t("Translation.R3.T5")}</p>
                </td>
              </tr>

              <tr>
                <td  className="bg-CBBDBF">
                  <p className="fw-700 m-0 ">{t("Translation.R4.T1")}</p>
                </td>
                <td className="bg-F1F1F2 border-top-white" dangerouslySetInnerHTML={{__html : t("Translation.R4.T2") }}>
                </td>
                <td className="bg-E6E7E8 border-top-white">
                  <p className="m-0">{t("Translation.R4.T3")}</p>
                </td>
                <td className="bg-DCDDDE border-top-white">
                  <p className="m-0">{t("Translation.R4.T4")}</p>
                </td>
                <td className="bg-D1D3D4 border-top-white">
                  <p className="m-0">{t("Translation.R4.T5")}</p>
                </td>
              </tr>
              <tr>
              <td colSpan={5} className=" bg-E0D8DA border-top-white border-right-white" >
                  <p className="fw-700 mb-1" dangerouslySetInnerHTML={{__html :t("Translation.R5.T1") }}>
                   
                  </p>
                  <p className="text-7C7B7B fs-12 ms-3 mb-0" dangerouslySetInnerHTML={{__html :t("Translation.R5.T1.sp") }}>
                  
                  </p>
                </td>
              </tr>
              <tr>
                
                <td className="bg-D3CCCE border-top-white">
                  <p className="fw-700 mb-1">{t("Translation.R5.T2")}</p>
                  <p className="text-7C7B7B fs-12 m-0" dangerouslySetInnerHTML={{__html : t("Translation.R5.T2.sp")}}>
                    
                  </p>
                </td>
                <td colSpan={4} className="bg-white border-top-white">
                  <p className="m-0" >
                   {t("Translation.R5.T3")}
                  </p>
                </td>
              </tr>

              <tr>
                <td className="bg-CAC3C6 border-top-white">
                  <p className="fw-700 mb-1">{t("Translation.R6.T1")}</p>
                  <p className="text-7C7B7B fs-12 m-0" dangerouslySetInnerHTML={{__html : t("Translation.R6.T1.sp") }}>
                   
                  </p>
                </td>
                <td className="bg-F1F1F2 border-top-white" dangerouslySetInnerHTML={{__html : t("Translation.R6.T2")}}>
                </td>
                <td className="bg-E6E7E8 border-top-white" dangerouslySetInnerHTML={{__html : t("Translation.R6.T3")}}>
                </td>
                <td className="bg-DCDDDE border-top-white" dangerouslySetInnerHTML={{__html : t("Translation.R6.T4")}}>
                </td>

                <td className="bg-D1D3D4 border-top-white">
                  <p className="m-0" dangerouslySetInnerHTML={{__html : t("Translation.R6.T5")}}>
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan={5} className="bg-gold-light2">
                  <p className="m-0" dangerouslySetInnerHTML={{__html : t("Translation.R7.T1")}}>
                  </p>
                </td>
              </tr>

              <tr>
                <td  className="bg-CBBDBF">
                  <p className="fw-700 m-0 ">{t("Translation.R8.T1")}</p>
                </td>
                <td className="bg-F1F1F2">
                  <p className="m-0">{t("Translation.R8.T2")}</p>
                </td>
                <td className="bg-E6E7E8">
                  <p className="m-0">{t("Translation.R8.T3")}</p>
                </td>
                <td className="bg-DCDDDE">
                  <p className="m-0">{t("Translation.R8.T4")}</p>
                </td>
                <td className="bg-D1D3D4">
                  <p className="m-0">{t("Translation.R8.T5")}</p>
                </td>
              </tr>
            </table>
          </div>
      </div>
    </FrontLayout>
  );
};

export default CancellationPolicy;
